import "./style.css";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";

import vertexShader from "./shaders/vertex.glsl";
import fragmentShader from "./shaders/fragment.glsl";

import vertexShader2 from "./shaders/vertex2.glsl";
import fragmentShader2 from "./shaders/fragment2.glsl";

import { gsap } from "gsap";

class Sketch {
  constructor() {
    this.screen = {
      height: window.innerHeight,
      width: window.innerWidth,
    };

    this.canvas = document.querySelector(".webgl");

    this.renderer = null;

    this.scroll = {
      current: 0,
      target: 0,
      ease: 0.075,
    };

    this.time = 0;

    this.init();
  }

  setUp() {
    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.canvas,
    });

    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.screen.width, this.screen.height);
  }

  cameraSetup() {
    this.camera = new THREE.PerspectiveCamera(
      70,
      this.screen.width / this.screen.height,
      0.001,
      1000
    );

    this.camera.position.set(0, 0, 1);

    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));
    const dotScreenPass = new FilmPass(
      0.1, // noise intensity
      0.01, // scanline intensity
      648, // scanline count
      false // grayscale
    );
    this.composer.addPass(dotScreenPass);
  }

  createBubble() {
    const geometry = new THREE.SphereGeometry(1.32, 32, 32);
    this.material = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: {
        time: { value: 0 },
      },
    });

    this.sphere = new THREE.Mesh(geometry, this.material);

    this.scene.add(this.sphere);

    const secondGeometry = new THREE.SphereGeometry(0.53, 128, 128);
    this.secondMaterial = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      vertexShader: vertexShader2,
      fragmentShader: fragmentShader2,
      uniforms: {
        time: { value: 0 },
      },
    });

    this.secondSphere = new THREE.Mesh(secondGeometry, this.secondMaterial);
    this.scene.add(this.secondSphere);
  }

  render() {
    this.time += 0.05;

    this.material.uniforms.time.value = this.time * 0.03;
    this.secondMaterial.uniforms.time.value = this.time * 0.03;

    window.requestAnimationFrame(this.render.bind(this));
    this.composer.render();
  }

  resize() {
    window.addEventListener("resize", () => {
      this.screen.width = window.innerWidth;
      this.screen.height = window.innerHeight;

      this.camera.aspect = this.screen.width / this.screen.height;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(this.screen.width, this.screen.height);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }

  animation() {
    gsap.to(".webgl", {
      opacity: 1,
      duration: 2.4,
      ease: gsap.Power2,
      delay: 0.6,
    });

    gsap.to(".header_left", {
      opacity: 1,
      duration: 2.4,
      ease: gsap.Power2,
      delay: 0.6,
    });

    gsap.to(".footer_left", {
      opacity: 1,
      duration: 2.4,
      ease: gsap.Power2,
      delay: 0.6,
    });

    gsap.to(".footer_right", {
      opacity: 1,
      duration: 2.4,
      ease: gsap.Power2,
      delay: 0.6,
    });

    gsap.to(".description", {
      y: 0,
      duration: 1.6,
      ease: gsap.Power4,
    });
  }

  init() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    this.setUp();
    this.cameraSetup();
    this.createBubble();
    // this.orbit = new OrbitControls(this.camera, this.renderer.domElement);
    this.render();
    this.resize();
    this.animation();
  }
}

new Sketch();
